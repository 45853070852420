export const SELFCARE_TESTS_URLS = {
    dev    : 'https://foundation-dev.priv.sewan.fr',
    staging: 'https://sophia-staging.sewan.fr',
};

const DOMAINS_EXTENSIONS = [
    'com',
    'fr',
    'eu',
    'ch',
    'be',
    'net',
    'org',
    'lu',
    'de',
    'info',
    'at',
    'it',
    'es',
    'tel',
];

export const SELFCARE_PROD_URLS = DOMAINS_EXTENSIONS.map((extension) => `https://myphoneboard.${extension}`);

export const SELFCARE_LIVE_URLS = {
    production: {
        fr: 'https://montableaudebord.fr',
        es: 'https://miarea.es',
        be: 'https://myselfcare.be',
    },
    staging: 'https://selfcare-staging.priv.sewan.fr',
};

export const LOCALHOST = 'localhost';
